/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Input,
  InputNumber,
  Table,
  Tooltip,
  TablePaginationConfig,
  Select,
} from 'antd';
import Monetary from 'components/UI/Data/Monetary';
import CurrencyInput from 'components/UI/CurrencyInputNew';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from 'store/state/slices';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import BuyIntentionOffers from 'components/Modals/BuyIntentionOffers';
import ConfirmationModal from 'components/Modals/Confirmation';
import * as BuyIntentionModels from 'model/BuyIntention';
import ObservationsOffer from '../../../components/Modals/BuyIntention/ObservationsOffer';
import { actions as buyIntentionActions } from '../../../store/state/buyIntention/buyIntentionSlice';
import * as BuyIntentionSelectors from '../../../store/state/buyIntention/buyIntenteionSelectors';
import * as S from './styles';

interface Props {
  tableData?: any;
}

const { Option } = Select;

function CurrencyValue(value: number) {
  const [maxFractionDigits, setMaxFractionDigits] = useState<number>(2);

  if (Number.isNaN(value)) return '-';

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <span
      onMouseOver={() => setMaxFractionDigits(6)}
      onMouseOut={() => setMaxFractionDigits(2)}
    >
      {new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: maxFractionDigits,
      }).format(value)}
    </span>
  );
}

export default function AddBuyIntentionTable({ tableData }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { PageSize, PageNumber } = useSelector(
    BuyIntentionSelectors.getPagination,
  );
  const deliveryDate = useSelector(BuyIntentionSelectors.getDeliveryDate);
  const businessDate = useSelector(BuyIntentionSelectors.getComercialDate);
  const offersList = useSelector(BuyIntentionSelectors.getOffersList);
  const idSelected = useSelector(BuyIntentionSelectors.getIdSelectedNow);
  const isLoading = useSelector(BuyIntentionSelectors.getIsLoading);
  const showModalOffers = useSelector(BuyIntentionSelectors.getShowModalOffers);
  const currentStep = useSelector(BuyIntentionSelectors.getCurrentStep);
  const reloadOffers = useSelector(BuyIntentionSelectors.getReloadOffers);
  const customer = useSelector(BuyIntentionSelectors.getCustomer);
  const visible = useSelector(BuyIntentionSelectors.getShowIntentionModal);
  const commercialDateFinish = useSelector(
    BuyIntentionSelectors.getComercialDateFinish,
  );
  const deliveryDateFinish = useSelector(
    BuyIntentionSelectors.getDeliveryDateFinish,
  );

  const [showRegisterConfirmationModal, setShowRegisterConfirmationModal] =
    useState<boolean>(false);
  const [showObservationModal, setShowObservationModal] =
    useState<boolean>(false);
  const [idOffer, setIdOffer] = useState<string | undefined>(undefined);
  const [obsText, setObsText] = useState<string>('');
  const [recordData, setRecordData] = useState<any>(undefined);
  const [priceValue, setPriceValue] = useState<number>(0);
  const [totalpriceProduct, setTotalPriceProduct] = useState<number>(0);
  const [totalpriceActual, setTotalPriceActual] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [nameProduct, setNameProduct] = useState<string>('');
  const [overviewItem, setOverviewItem] = useState<
    BuyIntentionModels.BuyIntentionData | undefined
  >(undefined);

  const handlePageChange = useCallback(
    (pagination: TablePaginationConfig) => {
      dispatch(
        buyIntentionActions.setPagination({
          PageSize: pagination.pageSize ?? 10,
          PageNumber: pagination.current ?? 1,
        }),
      );
    },
    [dispatch],
  );

  const choicePacking = (packingCode: string, offer: any) => {
    const composition = offer.productPreference.find(
      (packing: BuyIntentionModels.Packing) => packing.code === packingCode,
    );
    changeOfferInput(composition, 'packingSelected', offer);
    changeOfferInput(composition.qe, 'quantityPacking', offer);
    changeOfferInput(composition.qpe, 'quantityUnit', offer);
    console.log(composition);
  };

  const choiceProducer = (producerId: number, record: any) => {
    const producer = record.siteList.find(
      (item: BuyIntentionModels.Producer) => item.id === producerId,
    );
    if (producerId) {
      changeOfferInput(producer, 'site', record);
    } else {
      dispatch(buyIntentionActions.setClearProducerOnTheTable(record.id));
    }
  };

  const changeOfferInput = (value: any, property: string, record: any) => {
    if (record.id) {
      dispatch(
        buyIntentionActions.setPropertyValue({
          value,
          property,
          id: record.id,
        }),
      );
      if (currentStep === 1) {
        dispatch(
          buyIntentionActions.setOverview({
            value,
            property,
            id: record.id,
          }),
        );
      }
      if (!record.productPreference && record.site) {
        dispatch(buyIntentionActions.setIdSelectedNow(record.id));
        dispatch(
          BuyIntentionCalls.fetchPacking({
            siteId: record.site.id,
            productId: record.productId,
          }),
        );
        dispatch(buyIntentionActions.setIsLoading(true));
      }
    }
  };

  const handleChange = (value: any, record: any) => {
    changeOfferInput(value, 'unitPrice', record);
  };

  const showOffers = () => {
    dispatch(buyIntentionActions.setShowModalOffers(true));
    setShowRegisterConfirmationModal(false);
  };

  const addIntention = () => {
    setShowRegisterConfirmationModal(false);
    if (overviewItem) {
      dispatch(buyIntentionActions.setOverviewTable(overviewItem));
    }
    dispatch(
      buyIntentionActions.setPropertyValue({
        value: true,
        property: 'intention',
        id: idOffer,
      }),
    );
  };

  const searchOffers = (record: BuyIntentionModels.BuyIntentionData) => {
    let validator = 0;
    if (!deliveryDateFinish || deliveryDateFinish === '') {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar a data de entrega',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!commercialDateFinish || commercialDateFinish === '') {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar a data comercial',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!record.site || record.site === null) {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar o Produtor',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!record.qualitySelect || record.qualitySelect === '') {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar a Qualidade',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!record.quantityPacking || record.quantityPacking === 0) {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar o QE',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!record.quantityUnit || record.quantityUnit === 0) {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar o QpE',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (!record.unitPrice || record.unitPrice === 0) {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: 'É obrigatório informar o valor do produto',
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    if (validator === 0 && !record.bought && !record.intention) {
      setOverviewItem(record);
      dispatch(buyIntentionActions.setIdProductAdded(record.id));
      dispatch(buyIntentionActions.setSubPriceAdded(record.totalPrice));
      dispatch(
        BuyIntentionCalls.fetchOffersList({
          offerTypes: ['onSite', 'directed'],
          productsIds: [record.productId],
          producerIds: [record.site?.producerId],
          qualities: [record.qualitySelect === 'A1' ? 1 : 2],
          deliveryDate,
          businessDate,
          customerId: customer?.id,
        }),
      );
      dispatch(buyIntentionActions.setIdSelectedNow(record.id));
      dispatch(buyIntentionActions.setIsLoading(true));
      setIdOffer(record.id);
    }
  };

  const validatorAlign = (isDisabled: boolean, isValue: boolean) => {
    if (isDisabled) {
      return 0;
    }
    if (isValue && !isDisabled) {
      return 0;
    }
    return 9;
  };

  const handleTotalPrice = useCallback(
    (record: BuyIntentionModels.BuyIntentionData) => {
      // console.log('calculo', record.unitPriceNumber + record.shippingFeeFilials?.productShippingValue + record.packingSelected.packingCost)
      const QeXQpe = record.quantityUnit * record.quantityPacking;
      if (record.shippingFeeFilials?.productShippingValue) {
        if (record.packingSelected?.additionalCost === 'S') {
          return (
            (record.unitPrice +
              record.shippingFeeFilials?.productShippingValue +
              record.packingSelected.packingCost) *
            QeXQpe
          );
        }

        return (
          (record.unitPrice + record.shippingFeeFilials?.productShippingValue) *
          QeXQpe
        );
      }
      if (record.packingSelected?.additionalCost === 'S') {
        return (record.unitPrice + record.packingSelected.packingCost) * QeXQpe;
      }
      return record.unitPrice * QeXQpe;
    },
    [],
  );

  useEffect(() => {
    if (reloadOffers) {
      if (offersList.length && idSelected === idOffer) {
        setShowRegisterConfirmationModal(true);
      }
      if (offersList.length === 0 || !offersList) {
        if (idOffer) {
          dispatch(
            buyIntentionActions.setPropertyValue({
              value: true,
              property: 'intention',
              id: idOffer,
            }),
          );
          if (overviewItem) {
            dispatch(buyIntentionActions.setOverviewTable(overviewItem));
          }

          setTotalPriceActual(totalpriceProduct);
        }
      }
      dispatch(buyIntentionActions.setReloadOffers(false));
    }
  }, [offersList, reloadOffers]);

  function getPopupContainer(triggerNode: any) {
    return triggerNode.parentElement;
  }

  return (
    <>
      <ObservationsOffer
        onSubmit={changeOfferInput}
        visible={showObservationModal}
        setVisible={setShowObservationModal}
        valueObs={obsText}
        valueRecord={recordData}
      />

      <ConfirmationModal
        modalIsVisible={showRegisterConfirmationModal}
        actionButtonOk={() => showOffers()}
        actionButtonCancel={() => addIntention()}
        textButtonCancel={t('buyIntention.modals.registerBuyIntention')}
        textButtonOk={t('buyIntention.modals.showOffers')}
        textDescription={t('buyIntention.modals.questionOffers')}
        textTitle={t('buyIntention.modals.offersTitle')}
        widthModal={600}
      />

      <ConfirmationModal
        modalIsVisible={showDeleteModal}
        textTitle=" Excluir"
        textDescription={`Deseja excluir o Produto ${nameProduct}?`}
        textButtonOk="Sim"
        textButtonCancel="Não"
        actionButtonOk={() => {
          dispatch(buyIntentionActions.setDeleteOffer(idOffer ?? ''));
          setShowDeleteModal(false);
        }}
        actionButtonCancel={() => setShowDeleteModal(false)}
      />

      <BuyIntentionOffers
        modalIsVisible={showModalOffers}
        setModalIsVisible={() =>
          dispatch(buyIntentionActions.setShowModalOffers(false))
        }
      />

      <Table
        bordered
        dataSource={tableData}
        pagination={{
          current: PageNumber,
          pageSize: PageSize,
          showSizeChanger: true,
        }}
        onChange={(pagination) => handlePageChange(pagination)}
        rowKey="id"
      >
        <Table.Column
          key="productName"
          dataIndex="productName"
          title={t('buyIntention.table.productDescription')}
          render={(value, record: any) => (
            <S.GroupTitleInformation descroption>
              <Tooltip title={value}>
                <S.TitleTextWrap>{value}</S.TitleTextWrap>
                <S.DescriptionText>{record.productCode}</S.DescriptionText>
                <S.BoughtContent>
                  {record.bought && (
                    <S.BoughtText>
                      {t('buyIntention.table.bought')}
                    </S.BoughtText>
                  )}
                  {record.intention && currentStep === 0 && (
                    <S.BoughtText>
                      {t('buyIntention.table.intention')}
                    </S.BoughtText>
                  )}
                </S.BoughtContent>
              </Tooltip>
            </S.GroupTitleInformation>
          )}
        />

        <Table.Column
          width="25%"
          key="site"
          dataIndex="site"
          title={t('buyIntention.table.producer')}
          render={(value, record: BuyIntentionModels.BuyIntentionData) => (
            <Select
              dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
              placement="bottomLeft"
              getPopupContainer={(v) => getPopupContainer(v)}
              disabled={record.siteList === null}
              value={
                record.site !== null
                  ? `${record.site?.account} - ${record.site?.tradingName}`
                  : undefined
              }
              style={{ width: 250 }}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption
              placeholder={
                record.siteList === null
                  ? `${record.site?.account} - ${record.site?.tradingName}`
                  : 'Selecione'
              }
              onChange={(v) => choiceProducer(Number(v), record)}
            >
              {record.siteList !== undefined &&
                record.siteList !== null &&
                Array.isArray(record.siteList) &&
                record.siteList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {`${item.account} - ${item.tradingName}`}
                  </Option>
                ))}
            </Select>
          )}
        />

        <Table.Column
          key="quality"
          dataIndex="quality"
          title={t('buyIntention.table.quality')}
          render={(value, record: any) => (
            <Select
              getPopupContainer={(v) => getPopupContainer(v)}
              disabled={!record.site}
              style={{ width: 70 }}
              value={record.qualitySelect}
              allowClear={false}
              onChange={(v) => changeOfferInput(v, 'qualitySelect', record)}
            >
              {record.qualityList.map((item: any) => (
                <Option key={item.id} value={item.code}>
                  {item.description}
                </Option>
              ))}
            </Select>
          )}
        />

        <Table.Column
          dataIndex="productPreference"
          key="productPreferences"
          title={t('buyIntention.table.packing')}
          render={(
            productPreference: BuyIntentionModels.PackingFull[],
            record: BuyIntentionModels.BuyIntentionData,
          ) => (
            <>
              <Select
                getPopupContainer={(v) => getPopupContainer(v)}
                disabled={!productPreference}
                style={{
                  display: 'flex',
                  width: 80,
                  marginTop: validatorAlign(
                    !productPreference,
                    record.packingSelected?.additionalCost !== 'S',
                  ),
                }}
                bordered
                onChange={(v) => choicePacking(v, record)}
                value={record.packingSelected?.code || ''}
                allowClear={false}
                showSearch
                optionFilterProp="children"
                filterOption
                dropdownStyle={{ minWidth: '200px' }}
              >
                {productPreference &&
                  productPreference.map(
                    (packing: BuyIntentionModels.Packing) => (
                      <Option value={packing.code} key={packing.id}>
                        {packing.code} - {packing.abbrev}
                      </Option>
                    ),
                  )}
              </Select>
              {record.packingSelected?.additionalCost === 'S' && (
                <S.SubTextBody>
                  <Monetary symbol value={record.packingSelected.packingCost} />
                  /un.
                </S.SubTextBody>
              )}
            </>
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="quantityPacking"
          key="quantityPacking"
          title={
            <S.CenterContent>{t('buyIntention.table.qe')}</S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <>
              <InputNumber
                style={{
                  width: 70,
                  marginTop: !_record.packingSelected ? 0 : 10,
                }}
                disabled={!_record.packingSelected}
                inputMode="numeric"
                defaultValue={_record.packingSelected?.qe}
                value={value}
                min={1}
                step={_record.packingSelected?.qe || 1}
                onStep={(value) => {
                  if (value % _record.packingSelected?.qe !== 0) {
                    changeOfferInput(
                      _record.packingSelected?.qe,
                      'quantityPacking',
                      _record,
                    );
                  }
                }}
                onChange={(value_: any) => {
                  if (_record.unitPrice > 0) {
                    const price = _record.unitPrice;
                    const totalpriceCalc =
                      value_ *
                      _record.quantityUnit *
                      (price +
                        (_record.packingSelected.additionalCost === 'S'
                          ? _record.packingSelected.packingCost
                          : 0) +
                        (_record.shippingFeeFilials
                          ? _record.shippingFeeFilials.productShippingValue
                          : 0));
                    changeOfferInput(totalpriceCalc, 'totalPrice', _record);

                    setTotalPriceProduct(totalpriceCalc);
                    dispatch(
                      buyIntentionActions.setTotalPriceOffers({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                    dispatch(
                      buyIntentionActions.setTotalPriceOffersAux({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                    if (currentStep === 1) {
                      dispatch(
                        buyIntentionActions.setTotalPriceOverview({
                          id: _record.id,
                          value: totalpriceCalc,
                        }),
                      );
                    }
                  } else {
                    changeOfferInput(0, 'totalPrice', _record);
                    setTotalPriceProduct(0);
                  }

                  changeOfferInput(value_, 'quantityPacking', _record);
                }}
              />
              {_record.packingSelected && (
                <S.SubTextBody>
                  {`Múltiplo ${_record.packingSelected?.qe || ''}`}
                </S.SubTextBody>
              )}
            </>
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="quantityUnit"
          key="quantityUnit"
          title={
            <S.CenterContent>{t('buyIntention.table.qpe')}</S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <InputNumber
              style={{ width: 70 }}
              disabled={!_record.packingSelected}
              defaultValue={_record.packingSelected?.qpe}
              value={value}
              min={_record.packingSelected?.qpe || 1}
              step={1}
              type="number"
              onChange={(value_: any) => {
                if (_record.unitPrice > 0) {
                  const price = _record.unitPrice;
                  const totalpriceCalc =
                    value_ *
                    _record.quantityPacking *
                    (price +
                      (_record.packingSelected.additionalCost === 'S'
                        ? _record.packingSelected.packingCost
                        : 0) +
                      (_record.shippingFeeFilials !== null
                        ? _record.shippingFeeFilials.productShippingValue
                        : 0));
                  changeOfferInput(totalpriceCalc, 'totalPrice', _record);
                  setTotalPriceProduct(totalpriceCalc);
                  dispatch(
                    buyIntentionActions.setTotalPriceOffers({
                      id: _record.id,
                      value: totalpriceCalc,
                    }),
                  );
                  dispatch(
                    buyIntentionActions.setTotalPriceOffersAux({
                      id: _record.id,
                      value: totalpriceCalc,
                    }),
                  );
                  if (currentStep === 1) {
                    dispatch(
                      buyIntentionActions.setTotalPriceOverview({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                  }
                } else {
                  changeOfferInput(0, 'totalPrice', _record);
                  setTotalPriceProduct(0);
                }

                changeOfferInput(value_, 'quantityUnit', _record);
              }}
            />
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="unitPrice"
          key="unitPrice"
          title={
            <S.GroupTitleInformation>
              <S.TitleText>{t('buyIntention.table.unitValue')}</S.TitleText>
              <S.SubText>{t('buyIntention.table.noICMS')}</S.SubText>
            </S.GroupTitleInformation>
          }
          render={(
            priceByPacking,
            _record: BuyIntentionModels.BuyIntentionData,
          ) => (
            <CurrencyInput
              disabledOption={!_record.packingSelected}
              id={_record.id}
              value={priceByPacking}
              onChange={(e) => {
                handleChange(e, _record);
                const valuePrice = e;
                if (valuePrice > 0) {
                  setPriceValue(valuePrice);
                  const totalpriceCalc =
                    _record.quantityUnit *
                    _record.quantityPacking *
                    (valuePrice +
                      (_record.packingSelected?.additionalCost === 'S'
                        ? _record.packingSelected?.packingCost
                        : 0) +
                      (_record.shippingFeeFilials !== null
                        ? _record.shippingFeeFilials.productShippingValue
                        : 0));
                  changeOfferInput(totalpriceCalc, 'totalPrice', _record);
                  changeOfferInput(valuePrice, 'unitPriceNumber', _record);
                  setTotalPriceProduct(totalpriceCalc);
                  dispatch(
                    buyIntentionActions.setTotalPriceOffers({
                      id: _record.id,
                      value: totalpriceCalc,
                    }),
                  );
                  dispatch(
                    buyIntentionActions.setTotalPriceOffersAux({
                      id: _record.id,
                      value: totalpriceCalc,
                    }),
                  );
                  if (currentStep === 1) {
                    dispatch(
                      buyIntentionActions.setTotalPriceOverview({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                  }
                } else {
                  changeOfferInput(0, 'totalPrice', _record);
                  setTotalPriceProduct(0);
                }
              }}
            />
          )}
        />

        {customer?.branch === 'CVH_UB' && (
          <Table.Column
            width="10%"
            dataIndex="shippingFeeFilials"
            key="shippingFeeFilials"
            title={
              <S.GroupTitleInformation>
                <S.TitleText>{t('buyIntention.table.shippingFee')}</S.TitleText>
                <S.SubText>
                  {t('buyIntention.table.shippingTotalFee')}
                </S.SubText>
              </S.GroupTitleInformation>
            }
            render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
              <S.CenterContent>
                <span>
                  {CurrencyValue(
                    value !== null ? value.productShippingValue : 0,
                  )}
                </span>
                <S.SubTextBody>
                  <span>
                    {CurrencyValue(
                      _record.quantityPacking *
                        _record.quantityUnit *
                        (value !== null ? value.productShippingValue : 0),
                    )}
                  </span>
                </S.SubTextBody>
              </S.CenterContent>
            )}
          />
        )}

        <Table.Column
          width="10%"
          dataIndex="totalPrice"
          key="totalPrice"
          title={
            <S.CenterContent>
              {t('buyIntention.table.totalValue')}
            </S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <S.CenterContent>
              <Monetary symbol value={handleTotalPrice(_record)} />
              {_record.unitPrice > 0 && (
                <S.SubTextBody>
                  {_record.shippingFeeFilials?.productShippingValue ? (
                    <span>
                      {_record.packingSelected.additionalCost === 'S'
                        ? CurrencyValue(
                            _record.packingSelected?.packingCost +
                              _record.unitPrice +
                              _record.shippingFeeFilials?.productShippingValue,
                          )
                        : CurrencyValue(
                            _record.shippingFeeFilials?.productShippingValue +
                              _record.unitPrice,
                          )}
                    </span>
                  ) : (
                    <span>
                      {_record.packingSelected.additionalCost === 'S'
                        ? CurrencyValue(
                            _record.packingSelected?.packingCost +
                              _record.unitPrice,
                          )
                        : CurrencyValue(_record.unitPrice)}
                    </span>
                  )}
                </S.SubTextBody>
              )}
            </S.CenterContent>
          )}
        />

        <Table.Column
          dataIndex="observationText"
          key="observationText"
          title={
            <S.CenterContent>
              {t('buyIntention.table.observation')}
            </S.CenterContent>
          }
          render={(observation, record: any) => (
            <S.ObsContent>
              <Input
                value={observation}
                style={{
                  width: 100,
                }}
                onDoubleClick={() => {
                  setShowObservationModal(true);
                  setObsText(observation);
                  setRecordData(record);
                }}
                onChange={(e) =>
                  changeOfferInput(e.target.value, 'observationText', record)
                }
              />
            </S.ObsContent>
          )}
        />

        <Table.Column
          key="action"
          render={(
            _,
            record: BuyIntentionModels.BuyIntentionData,
            _index: number,
          ) => (
            <S.ObsContent>
              {currentStep === 0 ? (
                <Tooltip title="Adicionar produto">
                  <PlusOutlined onClick={() => searchOffers(record)} />
                </Tooltip>
              ) : (
                <DeleteOutlined
                  onClick={() => {
                    setShowDeleteModal(true);
                    setNameProduct(record.productName);
                    setIdOffer(record.id);
                  }}
                />
              )}
            </S.ObsContent>
          )}
        />
      </Table>

      {isLoading && (
        <S.Overlay>
          <S.Loader spinning={isLoading} size="large" />
        </S.Overlay>
      )}
    </>
  );
}
