import * as BuyIntentionModels from 'model/BuyIntention';
import * as Models from 'model/types';
import { Key } from 'react';
import baseApi from './config';

export function getProducts(params: Models.OrdersParam) {
  return baseApi.get(`api/sale`, {
    params,
  });
}

export function getProducers() {
  return baseApi.get(`/api/site/list-sites`);
}

export function getOffersByProducers(
  params: BuyIntentionModels.SearchProducer,
) {
  let url = `api/product/products-by-site?`;
  const entries = Object.entries(params);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=9999';
  return baseApi.get(url);
}

export function getOffersByProducts(params: BuyIntentionModels.SearchProducts) {
  return baseApi.get(
    `/api/product/products-by-search/${params.keyword}/${params.accountCode}`,
  );
}

export function getQuality() {
  return baseApi.get(`/product-quality`);
}

export function getPackings(params: BuyIntentionModels.FetchPackings) {
  return baseApi.get(`/packing/${params.siteId}/${params.productId}`);
}

export function postBuyIntention(params: any) {
  return baseApi.post(`/api/buyintention`, params);
}

export function putBuyIntention(params: any) {
  return baseApi.put(`/api/order/buy-intention/edit/${params.id}`, params.body);
}

export function deleteBuyIntention(ids: number[] | Key[]) {
  return baseApi.put(`/api/order/buy-intention/cancel`, ids);
}

export function republishBuyIntention(ids: number[] | Key[]) {
  return baseApi.put(`/api/order/buy-intention/republish`, ids);
}

export function updateDirectSale(params: BuyIntentionModels.UpdateDirectSale) {
  return baseApi.put(`/api/order/direct-sale?approve=${params.approve}`, {
    directSaleItemsIds: params.ids,
    justification: params.justification ?? null,
  });
}

export function requestOffer(
  productOfferFilterParam: Models.ProductOfferFilterParam,
) {
  const {
    name,
    initial,
    offerId,
    deliveryDate,
    businessDate,
    categories,
    offerTypes,
    productsIds,
    commercialGroupIds,
    siteId,
    qualities,
    totalPage,
    page,
    isMyOffers,
    colors,
    heights,
    dimensions,
    producerIds,
    customerId,
    orderBy,
    packagingByLayer,
  } = { ...productOfferFilterParam };
  const params = new URLSearchParams();
  if (name) params.append('name', `${name}`);
  if (initial) params.append('initial', `${initial}`);
  if (offerId) params.append('offerId', offerId.toString());
  if (offerId) params.append('offerId', offerId.toString());
  if (deliveryDate) params.append('deliveryDate', deliveryDate);
  if (isMyOffers) {
    params.append('isMyOffers', `${isMyOffers}`);
    params.append('totalPage', String(9999));
  }
  if (page) params.append('page', `${page}`);
  if (!isMyOffers && totalPage) params.append('totalPage', `${totalPage}`);
  if (businessDate) params.append('businessDate', businessDate);
  if (categories && categories.length > 0 && categories[0]) {
    categories.forEach((category) =>
      params.append('categoryIds', String(category)),
    );
  }
  if (siteId && siteId.length > 0 && siteId[0] !== 0) {
    siteId.forEach((site) => params.append('siteId', String(site)));
  }
  if (offerTypes && offerTypes.length > 0) {
    offerTypes.forEach((offerType) => params.append('offerTypes', offerType));
  }
  if (productsIds && productsIds.length > 0) {
    productsIds.forEach((productId) =>
      params.append('productsIds', String(productId)),
    );
  }
  if (commercialGroupIds && commercialGroupIds.length > 0) {
    commercialGroupIds.forEach((commercialGroupId) =>
      params.append('commercialGroupIds', String(commercialGroupId)),
    );
  }
  if (qualities && qualities.length > 0) {
    qualities.forEach((quality) => params.append('qualities', quality));
  }
  if (colors && colors.length > 0)
    colors.forEach((color) => params.append('colors', color));
  if (heights && heights.length > 0)
    heights.forEach((height) => params.append('heights', height));
  if (dimensions && dimensions.length > 0)
    dimensions.forEach((dimension) => params.append('dimensions', dimension));
  if (producerIds && producerIds.length > 0)
    producerIds.forEach((producerId) =>
      params.append('producerId', String(producerId)),
    );
  if (customerId && customerId > 0)
    params.append('customerId', String(customerId));

  if (packagingByLayer && packagingByLayer > 0)
    params.append('packagingByLayer', String(packagingByLayer));

  if (orderBy) params.append('orderBy', orderBy);

  return baseApi.get(`/api/Offer`, {
    params,
  });
}

export const onExport = async (params: any) => {
  const entries = Object.entries(params);
  let url = '/api/order/export?';
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      if (value === null) {
        url = url;
      } else if (key === 'Data.CustomerId') {
        url += `Data.CustomerId=${value}&`;
      } else {
        url += `${key}=${value}&`;
      }
    }
  }
  return baseApi.get(url);
};
