import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Space, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import Monetary from 'components/UI/Data/Monetary';
import * as MyOrderIntentionEditCalls from 'store/state/myOrderIntentionEdit/apiCalls';
import * as BuyIntentionModels from 'model/BuyIntention';
import { alertActions } from 'store/state/slices';
import * as MyOrderIntentionEditSelectors from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSelectors';
import { isMobile } from 'react-device-detect';
import { actions as MyOrderIntentionEditActions } from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSlice';
import ConfirmationModal from 'components/Modals/Confirmation';
import * as S from './styles';
import Step02Resume from './Steps/Step02Resume';
import OrderForm from './Form';

const { Step } = Steps;

export default function BuyIntentionModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(
    MyOrderIntentionEditSelectors.getShowIntentionModal,
  );
  const currentStep = useSelector(MyOrderIntentionEditSelectors.getCurrentStep);
  const overviewList = useSelector(
    MyOrderIntentionEditSelectors.getOverviewEdit,
  );
  const commercialDate = useSelector(
    MyOrderIntentionEditSelectors.getComercialDateFinish,
  );
  const deliveryDate = useSelector(
    MyOrderIntentionEditSelectors.getDeliveryDateFinish,
  );
  const tableData = useSelector(MyOrderIntentionEditSelectors.getOverviewEdit);
  const formInfo = useSelector(MyOrderIntentionEditSelectors.getFormInfo);

  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);

  const handleClickButtonPrimary = useCallback(() => {
    let validator = 0;
    if (commercialDate === '' || deliveryDate === '') {
      validator += 1;
      dispatch(
        alertActions.alertRequest({
          message: `É obrigatório informar as datas`,
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
    overviewList.forEach((offer: BuyIntentionModels.BuyIntentionData) => {
      if (offer.quantityUnit === null) {
        validator += 1;
        dispatch(
          alertActions.alertRequest({
            message: `É obrigatório informar o QpE do produto ${offer.productName}`,
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
      if (offer.quantityPacking === null) {
        validator += 1;
        dispatch(
          alertActions.alertRequest({
            message: `É obrigatório informar o QE do produto ${offer.productName}`,
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
      if (offer.unitPrice === 0) {
        validator += 1;
        dispatch(
          alertActions.alertRequest({
            message: `É obrigatório informar o Valor Unitário do produto ${offer.productName}`,
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
    });
    if (validator === 0) {
      const buyIntentionEditParams = {
        id: formInfo?.id,
        body: {
          deliveryDate,
          commercialDate,
          productId: overviewList[0].productId,
          siteId: overviewList[0].site?.id,
          qualityId: overviewList[0].qualitySelect === 'A1' ? 1 : 2,
          quantityPacking: overviewList[0].quantityPacking,
          packingId: overviewList[0].packingSelected.id,
          quantityUnit: overviewList[0].quantityUnit,
          unitPrice: overviewList[0].unitPriceNumber,
          observation: overviewList[0].observationText,
          statusId: 0,
        },
      };
      dispatch(
        MyOrderIntentionEditCalls.putBuyIntention(buyIntentionEditParams),
      );
      // dispatch(MyOrderIntentionEditActions.setIsLoading(true));
    }
  }, [dispatch, overviewList, commercialDate, deliveryDate, formInfo?.id]);

  const handleClickButtonSecondary = useCallback(() => {
    dispatch(MyOrderIntentionEditActions.setShowIntentionModal(false));
    dispatch(MyOrderIntentionEditActions.setSClearTotalPriceOffers());
    dispatch(MyOrderIntentionEditActions.setIdProductAdded(undefined));
    dispatch(MyOrderIntentionEditActions.setResetValues());
  }, [currentStep, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(MyOrderIntentionEditActions.setShowIntentionModal(false));
  }, [dispatch]);

  const steps = [
    {
      title: t('buyIntention.stepOne'),
    },
    {
      title: t('buyIntention.stepTwo'),
      content: <Step02Resume />,
    },
  ];

  return (
    <S.ModalAdd
      title=""
      width={1320}
      visible={visible}
      onCancel={handleCancel}
      style={{ borderRadius: 8 }}
      footer={null}
      maskClosable={false}
    >
      <S.ModalHeader>
        <span>Editar Intenção de Compra</span>
      </S.ModalHeader>

      <S.ModalBody>
        <Steps
          type="navigation"
          current={currentStep}
          className="site-navigation-steps"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <OrderForm />
        {currentStep === 1 && <Step02Resume />}
      </S.ModalBody>
      <S.ModalFooter>
        {isMobile ? (
          <Row>
            <Col style={{ width: '100%' }}>
              <Space>
                <S.ModalFooterInformationText>
                  <p>{t('buyIntention.offers.totalPrice')}</p>
                  <span>
                    <Monetary symbol value={tableData[0].totalPrice} />
                  </span>
                </S.ModalFooterInformationText>
              </Space>
              <S.ContentButtons>
                <Button
                  style={{ marginBottom: 8 }}
                  onClick={() => setShowModalCancel(true)}
                >
                  {t('buyIntention.cancel')}
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 8, marginBottom: 8 }}
                  onClick={handleClickButtonPrimary}
                >
                  Editar Intenção de Compra
                </Button>
              </S.ContentButtons>
            </Col>
          </Row>
        ) : (
          <Row justify="end">
            <Col>
              <Space>
                <S.ModalFooterInformationText>
                  <p>{t('buyIntention.offers.totalPrice')}</p>
                  <span>
                    <Monetary symbol value={tableData[0].totalPrice} />
                  </span>
                </S.ModalFooterInformationText>
                <Button
                  style={{ marginBottom: 8 }}
                  onClick={() => setShowModalCancel(true)}
                >
                  {t('buyIntention.cancel')}
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 8, marginBottom: 8 }}
                  onClick={handleClickButtonPrimary}
                >
                  Editar Intenção de Compra
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      </S.ModalFooter>
      <ConfirmationModal
        modalIsVisible={showModalCancel}
        textTitle="Cancelar Alteração"
        textDescription="Deseja descartar esta alteração?"
        textButtonOk="Sim"
        textButtonCancel="Não"
        actionButtonOk={() => handleClickButtonSecondary()}
        actionButtonCancel={() => setShowModalCancel(false)}
      />
    </S.ModalAdd>
  );
}
